
import "ip";
import "ipaddr.js";
import {icons,titleIcon} from "@/public/the-third/icon-map";
import {aliyunMenu,qcloudMenu,vmwareMenu,maasMenu,awsMenu} from "@/public/the-third/menu-json";
angular.module("main", [])
    .constant("GLOBAL_CONFIG", { "PAGESIZE": 10 })
    .value("APILOADING", {
        "reqNum": 0,
        "resNum": 0,
        "exclude": [".html",
            "awstack-user/v1/login",
            "awstack-resource/v1/firewall/askFirewallStatus",
            "awstack-resource/v1/vpn/askIPSecSiteStatus",
            "awstack-resource/v1/uploadimagez/space/info",
            "awstack-resource/v1/image/polling",
            "awstack-resource/v1/imagespolling",
            "awstack-resource/v1/physical/image/polling",
            // "awstack-resource/v1/server/",
            "check/enterprises/",
            "awstack-user/v1/nodes/status",
            "plugins/list",
            "awstack-user/v1/platform/version",
            "awstack-user/v1/storage",
            "awstack-resource/v1/images/isoMakeVolume",
            "/attachments",
            "awstack-user/v1/k8s/dockerclusters",
            "platform/initialization",
            "awstack-resource/v1/volume_type_initialize_all_in",
            "awstack-resource/v1/ironic/nodes",
            "awstack-boss/newResourceCharge/",
            //"awstack-user/v1/tdsql/instances",
            "/QueryAddGWInsance",
            "/AddInsToGroup",
            "instancetype/QueryExpandGWInstance",
            "instancetype/QueryInitGroupIns",
            "instancetype/QueryDelGWGroup",
            "queryaddinstogroup",
            "queryexpandgroupins",
            "querydelexpandins",
            "QueryInitInstance"
        ],
        "apiResTime": null
    })
    .value("NOPROJECTMENU", {
        //当为部门管理员时，且当前部门没有项目，需要把资源相关菜单屏蔽掉
        LIST: ['List', 'User', 'System'],
        InstallIronic: localStorage.installIronic,
        InstallK8s: localStorage.installK8s,
    })
    .controller("MainCtrl", ["$scope", "$rootScope", "alertSrv", "$translate", "$http", "$timeout", "$location", "$interval", "$window", "$route", "$uibModal", "ngTableDefaults", "APILOADING", "NOPROJECTMENU", "commonFuncSrv", "$translate", "insTab", "bobMesSrv", "accessPolicySrv","generalSrv","iframeUtil",
        function (scope, rootScope, alertSrv, translate, $http, $timeout, $location, $interval, $window, $route, $uibModal, ngTableDefaults, APILOADING, NOPROJECTMENU, commonFuncSrv, $translate, insTab, bobMesSrv, accessPolicySrv,generalSrv,iframeUtil) {
            var self = scope;
            self.$on('navToggle', function () {
                self.$broadcast('navToggleNew')
            })
            rootScope.iframeObj = {
                isIf:false,
                isShow:false,
                maskPending:0,//new-pro子项目 打开弹出层的个数，打开+1，关闭-1
                outBg:false//是否打开蒙层
            }
            rootScope.isLogined = false;//默认登录状态为false
            rootScope.isAuth = true;//是否为登录页面url
            rootScope.isView = false;//是否在概况页面
            rootScope.isSingle = false;//是否在概况页面
            rootScope.allMenu = [];//菜单默认为空
            rootScope.sideMenu = {
                sideMenuList: [],//当前左侧菜单数据
                activeSubMenu: "",//当前左侧菜单打开的二级菜单的位置
                sideMentTitle: "",//当前左侧菜单标题
                subMenuTitle: "",//当前页面在右侧显示的标题
                menuKeyword: "",//当前页面的keysword
                openFlag: {},
                icons,
                titleIcon
            };
            rootScope.services = {
                "backups": localStorage.backupsService,
                "cinder": localStorage.cinderService,
                "ceph": localStorage.cephService,
                "localDisk": localStorage.localDiskService
            }
            rootScope.miliFormat = commonFuncSrv.miliFormat();
            self.relogin = function () {
                $location.path("/");
                $location.search('helpmodular', null);// 登录时效 清除URL的helpmodular 防止帮助页面打开状态下 回到登录页
                rootScope.effeToken = false;
                rootScope.showNatureStart = false;
                rootScope.cloudGrading = false;
            }
            self.$on('get-create', function (e, val) {
                if (val && val.type) {
                    self.$broadcast("send-create", val);
                }
            })
            self.$on('region-refresh', function (e, val) {
                if (val && val.type) {
                    self.$broadcast("send-create-pd", val);
                }
            })
            //更新部门管理员登录时有无项目的菜单显示视图
            self.$on("update-menu", function (e) {
                rootScope.openStackMenu.child[0].child.forEach(item => {
                    if (NOPROJECTMENU.LIST.indexOf(item.keywords) < 0) {
                        item.noShow = 2;
                    }
                });
                localStorage.noProject = 1;
            })
            //配置全局搜索框的搜索函数
            self.defaultSettings = {
                filterOptions: {
                    filterFn: ageRangeFilter
                }
            };
            ngTableDefaults.settings = angular.extend({}, ngTableDefaults.settings, self.defaultSettings);
            function ageRangeFilter(data, filterValues/*, comparator*/) {
                var filterValuesArr = [];
                for (var i in filterValues) {
                    filterValuesArr.push(filterValues[i], i);
                } 
                return data.filter(function (item) {
                    if (item[filterValuesArr[1]] != undefined && (
                        item[filterValuesArr[1]] == filterValuesArr[0] ||
                        item[filterValuesArr[1]].toString().indexOf(filterValuesArr[0]) > -1)
                    ) {
                        return true;
                    } else {
                        return false;
                    }
                });
            }

            scope.dashAlerts = alertSrv;
            rootScope.enterpriseUid = localStorage.enterpriseUid;
            self.showCont = function (e, cont) {
                // if(cont.type=="danger"){
                //     cont.type="warning";
                //     cont.btnType="btn-warning";
                // }
                if (cont.btnType == "btn-primary") {
                    cont.btnType = "btn-info"
                }
                self.showAlert = true;
                self.delCont = cont.msg;
                self.ContType = cont.type || "warning";
                self.btnType = cont.btnType || "btn-warning";
                self.notDel = cont.notDel;
                self.showDel = cont.showDelBtn;
                self.delBtnText = cont.delBtnText || translate.instant('aws.tmpl.index.cancel')
                self.btnText = cont.btnText || translate.instant("aws.tmpl.index.ok");
                self.confirm = function () {
                    self.showAlert = false;
                    self.$broadcast(cont.target, cont.data, self);
                };
            };

            self.$on("delete", self.showCont);
            self.showError = function (e, cont) {
                self.del_errpr_cont = cont.msg;
                self.show_delete_error = true;
                self.confirm_error = function () {
                    self.show_delete_error = false;
                    self.$broadcast(cont.target, cont.data);
                }
            };
            self.$on("delete_error", self.showError);
            self.close = function () {
                self.showAlert = false;
            };
            self.close_error = function () {
                self.show_delete_error = false;
            }
            self.$on("closeNewWindow", self.close)
            self.openInitSettingModal = function () {
                //非admin,若vpc不能设置，则整个初始化设置功能不可用
                if (!rootScope.ADMIN && !self.canSetInitVpc) {
                    return;
                }
                $uibModal.open({
                    animation: true,
                    templateUrl: "js/initSetting/tmpl/initSetting.html",
                    controller: "initSettingCtrl",
                    resolve: {
                        mainScope: function () {
                            return self;
                        }
                    }
                });
            }

            /*容器和物理机安装部署安装完成提示怎么获取相关菜单*/
            self.$on("openPluginTips", function (e, data) {
                if (data == 'con') {
                    self.showConAlert = true;
                }
                if (data == 'phy') {
                    self.showPhyAlert = true;
                }
                self.$apply();
            });
            /*关闭容器提示*/
            self.hideConMsg = function () {
                self.showConAlert = false;
            };
            /*关闭物理机成功提示*/
            self.hidePhyMsg = function () {
                self.showPhyAlert = false;
            };

            //self.openInitSettingModal()
            self.$on("openInitSettingModal", self.openInitSettingModal);
            self.hideInitAlert = function () {
                self.showInitSettingAlert = false;
            };
            self.menuTitle = translate.instant("aws.property.side.title");
            rootScope.siteTitle = "";
            $http({
                url: "/awstack-user/v1/platform/version",
                method: "GET"
            }).then(function (res) {
                if (res && res.data) {
                    res.data = JSON.parse(res.data.description);
                    rootScope.versionType = translate.instant("aws.aboutVersion.versionEnterprise");
                    // rootScope.versionType=localStorage.permission=="stand"?translate.instant("aws.aboutVersion.versionStand"):translate.instant("aws.aboutVersion.versionEnterprise");
                    rootScope.systemVersion = res.data.version;
                    // rootScope.desc= res.data.desc+rootScope.versionType;
                    rootScope.siteTitle = res.data.title;
                    // rootScope.copyright = res.data.copyright;
                }

            });
            function changeSiteTitle(path) {
                var titles = path.replace("/", "").split("/");
                var title = titles.length == 1 ? (titles[titles.length - 1] == "" ? "home" : titles[titles.length - 1]) : titles[1];
                if (localStorage.managementRole == '9' && title == "operatelogs") { //审计员 审计日志
                    title = "auditLogs"
                }
                var trans_title = translate.instant("aws.siteTitle." + title);
                rootScope.pathTitle = trans_title;
            }

            self.activeMenu = function (evt, current) {
                scope.menuActive = current.originalPath.split("/")[2];
                scope.subMenuActive = current.originalPath.split("/")[3];
                changeSiteTitle(current.originalPath);
            };

            
            function getswitchData() {
                $http({
                    method: "GET",
                    url: "/awstack-user/v1/params",
                    params: {
                        "parentId": 724,
                        "enterpriseUid": localStorage.enterpriseUid,
                        "regionUid": localStorage.regionUid
                    }
                }).then(function (res) {
                    if (res && res.data && res.data.length != 0) {
                        var switchData = JSON.parse(res.data[0].paramValue).L3_Active;
                        rootScope.L3 = switchData
                    } else {
                        rootScope.L3 = true;
                    }
                });
            }

            function getBillingStatus() {
                if (localStorage.$LOGINDATA) {
                    $http({
                        method: "GET",
                        url: "awstack-user/v1/params?enterpriseUid=" + localStorage.enterpriseUid + "&parentId=80"
                    }).then(function (res) {
                        if (res && res.data && res.data[0]) {
                            if (res.data[0].paramValue == "1") { //1 打开 0 关闭
                                rootScope.billingActive = true;
                                $http({
                                    method: "GET",
                                    url: "awstack-boss/consumptionSatistics/queryLoginAccountConsumptionAmount",
                                    params: {
                                        userId: localStorage.userUid
                                    }
                                }).then(function (res) {
                                    if (res) {
                                        rootScope.loginAccountConsumeData = res.data;
                                    }
                                });
                            } else {
                                rootScope.billingActive = false;
                            }
                        }
                    });
                }
            }

            function getTotalData() {
                localStorage.TotalListName = "";
                $http({
                    method: "GET",
                    url: "/awstack-user/v1/" + localStorage.regionKey + '/' + localStorage.enterpriseUid + "/getTotalResourcesStatistics"
                }).then(function (res) {
                    if (res && res.data) {
                        let str = '';
                        if (Array.isArray(res.data) && res.data.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                const item = res.data[i];
                                let keyName = '';
                                if (item.name == 'ceph') {
                                    keyName = translate.instant('aws.indextran.ceph');
                                } else if (item.name == 'memory') {
                                    keyName = translate.instant('aws.indextran.memory');
                                } else {
                                    keyName = item.name;
                                }
                                str += keyName + translate.instant('aws.indextran.resourceOverflow') + item.value + '%，';
                            }
                            str += translate.instant('aws.indextran.pleaseExpand');
                        }
                        localStorage.TotalListName = str;
                        rootScope.ListName = str;
                        if (localStorage.TotalListName != "" && rootScope.isLogined) {
                            rootScope.totalResShow = true;
                        } else {
                            rootScope.totalResShow = false;
                        }
                    }

                });
            }

            //判断快捷操作中得vpc网络是否展示（根据localStorage.regionBusiAuth和定制化java返回的接口来表示rootScope.customizedIsAdmin)
            function needInitVpcNet(customizedIsAdmin) {
                let regionBusiAuth = localStorage.regionBusiAuth != 2 ? JSON.parse(localStorage.regionBusiAuth) : [];
                let index = regionBusiAuth.indexOf("3");
                if (index > -1 && customizedIsAdmin) {
                    self.canSetInitVpc = true;
                } else {
                    self.canSetInitVpc = false;
                }
            }
            
            /**
             * 获取负载均衡特性是否开启
             */
            function getNginxState(){
                // 判断负载均衡开关是否显示
                // const data = JSON.parse(localStorage.getItem('LicenseList'))
                // const tempObj = data.find(item => item.keywords == 'lbass-high-config')
                // rootScope.nginxIsShow = tempObj.selected;
                // if(tempObj.selected){
                // }
                generalSrv.saasConfInfo('1124','',localStorage.regionUid).then(res=>{
                    if(res&&res.data){
                        const data = res.data[0];
                        rootScope.isOpenF5 = data.paramValue == 0
                    }
                })
            }

            // 获取当前region所有菜单的url
            function getAllUrl(data,arr=[]){
                // let excludeList = ["/quickconfig/createins"];
                // let allUrlList = [];
                data.forEach(item=>{
                    if(item.href){
                        arr.push(item.href);
                    }
                    if(item.child.length>0){
                        getAllUrl(item.child,arr);
                    }
                })


                // if(data){
                //     allUrlList = data.match(/(\/[a-zA-Z0-9-_]+)+/g);
                //     allUrlList.push(...excludeList);
                // }
                return arr;
            }
            // 路由白名单
            function whiteFn(role,data){
                let extMenu = [];
                switch(role){
                    case '2':
                        extMenu = ["/quickconfig/createins","/single"];
                        break;
                    default:
                        extMenu = ["/quickconfig/createins"];
                }
                // 判断是否有流程管理菜单
                if(data.indexOf("/system/flowManage")>-1){
                    extMenu.push("/workflow/createflow")
                }
                return [...data,...extMenu]
            }

            self.$watch(function () {
                return rootScope.isLogined;
            }, function (v) {
                if (v) {
                    localStorage.goBack = true;
                    getswitchData();
                    getBillingStatus();
                    getNginxState();
                    if (localStorage.managementRole == "5" && localStorage.secretFreeLogin == "true") {
                        rootScope.freeLoginHide = true;
                    } else {
                        rootScope.freeLoginHide = false;
                    }
                    if (localStorage.managementRole == "2" || localStorage.managementRole == "8" || localStorage.managementRole == "9") {
                        getTotalData();
                        if (localStorage.TotalListName != "") {
                            rootScope.totalResShow = true;
                        }
                        //定制开发判断是否开启快捷操作中的云主机和vpc(非admin用户根据返回的值来进行展示，admin security audit不用做判断)
                        rootScope.customizedIsAdmin = true;
                        needInitVpcNet(rootScope.customizedIsAdmin);
                    } else {
                        $http({
                            url: "/awstack-user/v1/params?paramId=802",
                            method: "GET"
                        }).then(function (res) {
                            if (res && res.data && angular.isArray(res.data) && res.data.length) {
                                let switchValue = Number(res.data[0].paramValue);
                                if (switchValue == 1) {
                                    rootScope.customizedIsAdmin = true;
                                } else {
                                    rootScope.customizedIsAdmin = false;
                                }
                                needInitVpcNet(rootScope.customizedIsAdmin);
                            }
                        });
                    }
                    self.asde_menu = JSON.parse(localStorage.menuList);

                    rootScope.ListApplication = true;
                    if (localStorage.managementRole == 5) {
                        if (localStorage.menuList.indexOf('List_ListApplication') > -1) {
                            rootScope.ListApplication = false;
                        } else {
                            rootScope.ListApplication = true;
                        }
                    }

                    //快捷操作的显示
                    self.hasResManage = false;
                    self.hasUserManage = false;
                    for (var menu in self.asde_menu) {
                        if (self.asde_menu[menu].keywords == 'Resource') {
                            self.hasResManage = true;
                        } else if (self.asde_menu[menu].keywords == 'User') {
                            self.hasUserManage = true;
                        }
                    }
                    rootScope.logoUrl = self.asde_menu[0].href;
                    // localStorage.setItem("$MENUPREMIT", JSON.stringify(self.asde_menu));
                    self.isShowCloud = true;
                    // if(localStorage.permission!="enterprise"){
                    //     self.isShowCloud=false;
                    // }else{
                    //     self.isShowCloud=true;
                    // }
                } else {
                    rootScope.totalResShow = false;
                    rootScope.effeToken = false;
                }
            });
            self.$on("$routeChangeStart", function (event, next, cur) {
                let path = next.originalPath;
                self.allUrlList = '';
                if (path == "/") {
                    rootScope.isAuth = true;
                } else {
                    rootScope.isAuth = false;
                }
                
                if (path) {
                    path = path.split("/:")[0];
                }
                if(path&&path != "/" && localStorage.managementRole>2){
                    self.allUrlList = whiteFn(localStorage.managementRole,getAllUrl(JSON.parse(localStorage.menuList)))
                    if(self.allUrlList.indexOf(path)<0){
                        $location.url(self.allUrlList[0]);
                    }
                }
                
            });
            //
            self.changeMenuFlag = function (item) {
                if (item.child.length == 0 && item.href.indexOf('tstack/login') > -1) {
                    self.jumpSkyCloudSecurity(item);
                    return;
                }
                if (item.href && item.href.indexOf('maxscreen') > -1) {
                    self.jumpScreen();
                    return;
                }
                if (item.href && item.href.indexOf('integrated') > -1) {
                    self.jumpIntegrated();
                    return;
                }
                if (item.href) {
                    $location.path(item.href);
                }
                rootScope.sideMenu.activeSubMenu = item.keywords;
                if (rootScope.sideMenu.openFlag[item.keywords]) {
                    rootScope.sideMenu.openFlag[item.keywords] = false;
                } else {
                    for (var i in rootScope.sideMenu.openFlag) {
                        rootScope.sideMenu.openFlag[i] = false;
                    }
                    rootScope.sideMenu.openFlag[item.keywords] = true;
                }
            }
            self.menuLinkTo = function(item){
                if(item.href && item.href.indexOf('maxscreen') > -1) {
                    self.jumpScreen();
                    return;
                }
                if(item.href && item.href.indexOf('TBase/TStudio') > -1) {
                    self.jumpTStudio();
                    return;
                }
                if(item.keywords=='System_reportForms') {
                    self.jumpReportForm();
                    return;
                }
                if(item.href && item.href.indexOf('tstack/login')>-1) {
                    self.jumpSkyCloudSecurity(item);
                    return;
                }
                if(item.keywords.indexOf('Tsafe')>-1) {
                    self.tsafeWaf(item);
                    return;
                }
                if(item.href){
                    $location.path(item.href);
                }
            }
            self.checkActive = function (item) {
                let fullpath = $location.path();
                let fullpathArr = fullpath.split("/");
                if (item.active && _.include(item.active.split(","), fullpathArr[2])) {
                    self.$broadcast("to-parent");
                }
                if (item.active) {
                    return _.include(item.active.split(","), fullpathArr[1]) || _.include(item.active.split(","), fullpathArr[2]);
                }
            };
            
            function changeMenu(evt, current, prev) {
                var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                if (current && current.parent == "Alauda") {
                    if (current.active == 'alRegister') {
                        rootScope.alaudaURL = PaasData['Alauda'].url + "/dex/auth/simpletoken?client_id=alauda-auth&token=" + localStorage.$AUTH_TOKEN + "&redirect_uri=" + current.jump;
                    } else {
                        rootScope.alaudaURL = PaasData['Alauda'].url + "/dex/auth/simpletoken?client_id=alauda-auth&token=" + localStorage.$AUTH_TOKEN + "&redirect_uri=" + PaasData['Alauda'].url + "/console/#" + current.jump;
                    }
                }
                if (current && current.parent == "TBase") {
                    rootScope.alaudaURL = PaasData['TBase'].url + current.jump;
                }
                if (current && current.parent == "TDSQL") {
                    rootScope.alaudaURL = PaasData['TDSQL'].url + current.jump + '&token=' + localStorage.$AUTH_TOKEN;
                }
                if (current && current.parent == "CTSDB") {
                    var CTurl = PaasData['CTSDB'].url + '?token=' + localStorage.$AUTH_TOKEN + "&userid=" + localStorage.$USERID + "&username=" + localStorage.userName;
                    rootScope.alaudaURL = CTurl + current.jump;
                }
                if (current && current.parent == "COC") {
                    //rootScope.alaudaURL = PaasData['COC'].url+current.jump+'?token=' + localStorage.$AUTH_TOKEN;
                    rootScope.alaudaURL = 'http://zhiyun.qcloud.com' + current.jump + '?token=' + localStorage.$AUTH_TOKEN;
                }
                if (current && current.parent == "TSF") {
                    var CTurl = PaasData['TSF'].url + current.jump + '?rid=1&ticket=' + localStorage.$AUTH_TOKEN;
                    rootScope.alaudaURL = CTurl;
                }
                //消息队列中间件 
                if (current && current.parent == "CMQ") {
                    var CTurl = PaasData['CMQ'].url + current.jump + '?rid=1&ticket=' + localStorage.$AUTH_TOKEN;
                    rootScope.alaudaURL = CTurl;
                }


                if (current && current.parent == "TBDS") {
                    rootScope.alaudaURL = PaasData['TBDS'].url + current.jump;
                }
                self.allMenu = "";
                let fullpath = current.originalPath;
                rootScope.currentPath = fullpath;
                rootScope.lazyOnType = {};
                rootScope.sideMenu.isIframe = current.isIframe;
                if (current && current.active) {
                    if (localStorage.managementRole == '9' && current.active == "operatelogs") { //审计员 审计日志
                        rootScope.sideMenu.menuKeyword = 'auditLogs';
                    } else {
                        rootScope.sideMenu.menuKeyword = current.active;
                    }

                    rootScope.sideMenu.activeSubMenu = current.parent;
                }
                let menu = localStorage.menuList ? JSON.parse(localStorage.menuList) : "";
                self.securityoraudit=false;//隐藏返回图标 tcs 相关
                if (current.parent) {
                    //普通用户，如果是多级菜单，则需要修改相应的parent List,System,Log,User,Monitoring,Flowing,PhysicalResource,Bill_Management,K8s,Sky_entrance_tenant,DatabaseManage
                    var paramlist = "List,System,Log,User,Monitoring,Flowing,PhysicalResource,Bill_Management,K8s,Sky_entrance_tenant,DatabaseManage";
                    var securitys = "User";//tcs 相关
                    if (localStorage.managementRole == 5 && (paramlist.indexOf(current.parent) > -1)) {
                        current.parent = "Resource";
                    }
                    if ((prev && prev.parent != current.parent) || !prev) {
                        for (let k = 0; k < menu.length; k++) {
                            if(current.parent.indexOf("TCS_")>-1 && menu[k].keywords == "TCS") {
                                for(let x=0;x<menu[k].child.length;x++){
                                    //如果是云硬盘CBS 进一层判断
                                    if((current.parent.indexOf("TCS_cbs_")>-1 && menu[k].child[x].keywords == 'TCS_cbs') 
                                        || (current.parent.indexOf("TCS_cos_")>-1 && menu[k].child[x].keywords == 'TCS_cos')
                                        || (current.parent.indexOf("TCS_tsf_")>-1 && menu[k].child[x].keywords == 'TCS_tsf')
                                        || (current.parent.indexOf("TCS_tdmq_")>-1 && menu[k].child[x].keywords == 'TCS_tdmq')
                                        || (current.parent.indexOf("TCS_api_")>-1 && menu[k].child[x].keywords == 'TCS_api')
                                        || (current.parent.indexOf("TCS_ckafka_")>-1 && menu[k].child[x].keywords == 'TCS_ckafka')
                                        || (current.parent.indexOf("TCS_tenant_")>-1 && menu[k].child[x].keywords == 'TCS_tenant')){
                                        for(let y=0;y<menu[k].child[x].child.length;y++){
                                            if(menu[k].child[x].child[y].keywords==current.parent){
                                                rootScope.sideMenu.sideMenuList = menu[k].child[x].child;
                                                rootScope.sideMenu.sideMentTitle = menu[k].child[x].keywords;
                                                break;
                                            }
                                        }
                                    }
                                    if(menu[k].child[x].keywords==current.parent){
                                        rootScope.sideMenu.sideMenuList = menu[k].child[x].child;
                                        rootScope.sideMenu.sideMentTitle = menu[k].child[x].keywords;
                                        break;
                                    }
                                } 
                            }else{
                                if(localStorage.managementRole==5&&menu[k].keywords.indexOf(current.parent)>-1){
                                    rootScope.sideMenu.sideMenuList = menu[k].child;
                                    rootScope.sideMenu.sideMentTitle = menu[k].keywords;
                                    break;
                                }else if((localStorage.managementRole==8 || localStorage.managementRole==9) && securitys.indexOf(current.parent) > -1){
                                    //tcs 相关
                                    rootScope.sideMenu.sideMenuList = menu[k].child;
                                    rootScope.sideMenu.sideMentTitle = menu[k].keywords;
                                    break;
                                }else{
                                    if(menu[k].keywords==current.parent){
                                        rootScope.sideMenu.sideMenuList = menu[k].child;
                                        rootScope.sideMenu.sideMentTitle = menu[k].keywords;
                                        break;
                                    } 
                                }
    
                            }
                        }
                    }
                }
                
                //如果是保密员和审计员增加返回按钮 tcs 相关
                if(localStorage.managementRole==8 || localStorage.managementRole==9){
                    self.securityoraudit=true;
                }
                self.changeSecurityoraudit = function() {
                    if(localStorage.managementRole==8 ){
                        location.replace("#/permit/secrecyRole");
                    }
                    if(localStorage.managementRole==9) {
                        location.replace("#/permit/auditRole");
                    }
                }
                if (rootScope.sideMenu.sideMenuList.length > 0) {
                    for (let i = 0; i < rootScope.sideMenu.sideMenuList.length; i++) {
                        var cm = rootScope.sideMenu.sideMenuList[i].active.split(",");
                        if (cm.indexOf(current.active) > -1) {
                            rootScope.sideMenu.activeSubMenu = rootScope.sideMenu.sideMenuList[i].keywords;
                            break;
                        }
                    }
                    rootScope.sideMenu.sideMenuList.forEach(iii=>{
                        if(iii.keywords=="Tsafe_menu"){
                            iii.child = iii.child.filter(iiii=>{
                                let k = iiii.active;
                                return PaasData[k]
                            })
                        }
                    })
                    /*控制菜单开合*/
                    rootScope.sideMenu.openFlag[rootScope.sideMenu.activeSubMenu] = true;
                }
            }
            function clearApiLoading() {
                /*if(APILOADING.reqNum>APILOADING.resNum){
                    APILOADING.resNum=0;
                    APILOADING.reqNum=APILOADING.reqNum-APILOADING.resNum;
                }else{
                    APILOADING.reqNum=0;
                    APILOADING.resNum=0;
                }*/
                $("html").scrollTop(0);

            }
            function getAdConfig() {
                rootScope.AD = { enabled: true, isLoad: false };
                $http({
                    method: "GET",
                    url: "/awstack-user/v1/ldap/config",
                }).then(function (res) {
                    if (res && res.data) {
                        rootScope.AD.enabled = res.data.enable;
                    } else {
                        rootScope.AD.enabled = false;
                    }
                    rootScope.AD.isLoad = true;
                })
            }
            function getInitSettingStatus(originalPath) {
                $http({
                    method: "GET",
                    url: "awstack-user/v1/ent/" + localStorage.enterpriseUid + "/platform/initialization"
                }).then(function (result) {
                    if (result && result.data && angular.isArray(result.data)) {
                        result.data.forEach(function (item, index) {
                            if (item.regionKey == localStorage.regionKey) {
                                rootScope.vpcStatus = item.vpc;
                                rootScope.alarmStatus = item.alarm;
                                if (item.cinderVolume == 0) {
                                    rootScope.storageStatus = false;
                                } else if (item.cinderVolume == 1) {
                                    rootScope.storageStatus = false;
                                } else if (item.cinderVolume == 2) {
                                    rootScope.storageStatus = true;
                                } else {
                                    rootScope.storageStatus = false;
                                }
                                if (item.image == 0) {
                                    rootScope.transferStatus = false;
                                } else if (item.image == 1) {
                                    rootScope.transferStatus = false;
                                } else if (item.image == 2) {
                                    rootScope.transferStatus = true;
                                } else {
                                    rootScope.transferStatus = false;
                                }

                                var status = !item.status;
                                if (originalPath == "/view") {
                                    self.showInitSettingAlert = false;
                                } else {
                                    self.showInitSettingAlert = status;
                                }
                                if (rootScope.firstInitSetting == 0 && status && localStorage.managementRole == 2) {//todos 暂时只允许admin进行初始化操作，权限控制会影响此逻辑，比如：当没有网络权限时，其他用户是否允许做初始化
                                    self.openInitSettingModal();
                                    rootScope.firstInitSetting = 1;
                                }
                            }
                        });
                    }
                });
            }

            // 关闭授权到期提醒
            rootScope.closefn = function(){
                const enterpriseUid = localStorage.getItem('enterpriseUid');
                const userUid = localStorage.getItem('userUid');
                $http({
                    method: "post",
                    url: `/awstack-user/v1/savePoint?enterpriseUid=${enterpriseUid}&userUid=${userUid}`,
                }).then(() => {
                    rootScope.close_alert = false;
                    rootScope.localExpire()
                })
            }
            
            self.$on("$routeChangeSuccess", function (evt, current, prev) {
                bobMesSrv.isLoginLog = $location.url() == "/logs/loginlogs" ? true : false;
                rootScope.isLogined = localStorage.$AWLOGINED==2 || false;//是否登录
                self.securityStatus = true;
                if (rootScope.isLogined) {
                    rootScope.loginData = JSON.parse(localStorage.$LOGINDATA);
                }
                if (rootScope.isLogined && current.originalPath && current.originalPath != ("/") && !(localStorage.canPlatformInit == 1)) {
                    getInitSettingStatus(current.originalPath);
                    getAdConfig();
                } else {
                    self.showInitSettingAlert = false;
                }
                clearApiLoading();
                self.close();//关闭删除资源的提示弹出框
                self.activeMenu(evt, current);//
                changeMenu(evt, current, prev);
                iframeUtil.setIframeMeta(current,rootScope.iframeObj);
                rootScope.ADMIN = false;
                rootScope.DOMAIN_ADMIN = false;
                rootScope.PROJECT_ADMIN = false;
                rootScope.MEM_ADMIN = false;
                rootScope.SECURITY = false;
                rootScope.AUDIT = false;
                switch (localStorage.managementRole) {
                    case "2":
                        rootScope.ADMIN = true;
                        break;
                    case "3":
                        rootScope.DOMAIN_ADMIN = true;
                        break;
                    case "4":
                        rootScope.PROJECT_ADMIN = true;
                        break;
                    case "5":
                        rootScope.MEM_ADMIN = true;
                        //如果安装了TSF，则放开头部菜单
                        var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                        if (PaasData != null && (PaasData['TSF'] != null || PaasData['CMQ'] != null || PaasData ['TCS_API_KEY'] != null )) {
                            var menu = angular.fromJson(localStorage.menuList);
                            if (menu.length > 1) {
                                rootScope.MEM_ADMIN = false;
                            }
                        }
                        //如果安装了腾讯云安全，则放开头部菜单
                        if(localStorage.supportOtherClouds.indexOf('Tsafe_API_KEY')>-1){
                            rootScope.MEM_ADMIN = false;
                        }
                        break;
                    case "8":
                        rootScope.SECURITY = true;
                        // tcs 相关
                        var PaasData = localStorage.supportPaas?JSON.parse(localStorage.supportPaas):{};
                        if (PaasData !=null && ( PaasData ['TCS_API_KEY'] != null )){
                            var menu = angular.fromJson (localStorage.menuList);
                            if ( menu.length > 1){
                                self.securityoraudit = true;//展示返回图标
                            }
                            else {
                                self.securityoraudit = false;//隐藏返回图标
                            }
                        }else  {
                            self.securityoraudit = false;//隐藏返回图标
                        }

                        // rootScope.MEM_ADMIN = true;//系统保密员 审计员 顶部菜单按钮控制  by heliang 20181226
                        break;
                    case "9":
                        rootScope.AUDIT = true;
                        // tcs 相关
                        var PaasData = localStorage.supportPaas?JSON.parse(localStorage.supportPaas):{};
                        if (PaasData !=null && ( PaasData ['TCS_API_KEY'] != null )){
                            var menu = angular.fromJson (localStorage.menuList );
                            if ( mefnu.length > 1){
                                self.securityoraudit = true;//展示返回图标
                            }
                            else {
                                self.securityoraudit = false;//隐藏返回图标
                            }
                        }else  {
                            self.securityoraudit = false;//隐藏返回图标
                        }

                        // rootScope.MEM_ADMIN = true;
                        break;
                }
                $interval.cancel($window.rollpolingNodes);
                $interval.cancel($window.firewallTimer);
                $window.IntervalResize = null;
                $window.IntervalImageResize = null;
                $window.updataImageInterval = null;
                $window.nodeProvisionToAvalFunc = null;
                $window.nodeProvisionFunc = null;
                $window.nodeInterFunc = null;
                $window.IntervalFirewallResize = null;
                $window.IntervalVpnResize = null;
                $window.IntervalNodesResize = null;
                $window.InterFunc = null;
                if (current && current.originalPath && current.originalPath == "/view") {
                    localStorage.domainUid = localStorage.defaultdomainUid;//部门Uid
                    localStorage.domainName = localStorage.defaultdomainName;//部门Name
                    localStorage.projectUid = localStorage.defaultProjectUid;//项目Uid
                    localStorage.projectName = localStorage.defaultProjectName;//项目Name
                }

                if (!current.originalPath || current.originalPath == ("/")) {
                    rootScope.isLogined = false;
                    localStorage.removeItem("$AWLOGINED");
                    localStorage.removeItem("rolename");
                }
                let noSideMenu = ["/view","/single"];
                if (current && current.originalPath&&noSideMenu.indexOf(current.originalPath)>-1) {
                    rootScope.isView = true;
                } else {
                    rootScope.isView = false;
                }
                if(current && current.originalPath=="/quickconfig/createins"){
                    rootScope.isEasyCreateVm = true;
                }else{
                    rootScope.isEasyCreateVm = false;
                }
                if (current.originalPath == "/system/billingManagement") {
                    rootScope.inBillingModule = true;
                }
            });

            self.viewLoginAccountConsumeDetail = function () {
                rootScope.inBillingModule = false;
                let orgPath = angular.copy($location.path());
                $location.path($location.path()).search({
                    "consumeUserId": localStorage.userUid,
                    "name": localStorage.userName,
                    "type": "user",
                    "from": "global"
                });
                rootScope.userAnimation = "animateIn";
                $("body").addClass("animate-open");
                rootScope.$emit("userConsumeDetail", {
                    name: localStorage.userName,
                    id: localStorage.userUid,
                    from: "global",
                    topPath: orgPath
                });
            };

            self.viewUserResConsumeDetail = function (item) {
                let orgPath = angular.copy($location.path());
                $location.path($location.path()).search({
                    "consumeUserId": $location.search().consumeUserId,
                    "name": $location.search().name,
                    "regionName": $location.search().regionName,
                    "deptId": $location.search().deptId,
                    "projectId": $location.search().projectId,
                    "type": "user",
                    "resName": item.resourceName,
                    "from": $location.search().from
                });
                rootScope.userResAnimation = "animateIn";
                rootScope.$emit("userResConsumeDetail", {
                    name: $location.search().name,
                    id: $location.search().consumeUserId,
                    resName: item.resourceName,
                    from: "global",
                    topPath: orgPath
                });
            };

            self.returnOrgPath = function (type) {
                if (type == 'user') {
                    rootScope.userAnimation = "animateOut";
                    rootScope.userResAnimation = "animateOut";
                    $("body").removeClass("animate-open");
                    $location.path($location.path()).search({});
                } else if (type == 'userRes') {
                    rootScope.userResAnimation = "animateOut";
                    $location.path($location.path()).search({
                        "consumeUserId": $location.search().consumeUserId,
                        "name": $location.search().name,
                        "type": "user",
                        "from": $location.search().from
                    });
                }
            };
            // 打开修改密码弹窗
            self.adminPasswordModify = async function () {
                const res = await accessPolicySrv.getLoginConfig({ parentId: 2 })

                const pwd_config = JSON.parse(res.data.find(item=>item.paramName == 'LOGIN_PASSWORD_RANK').paramValue)

                // "登密码级别，1代表弱，2代表中，3代表强"
                const { passwordrank, lengthmin, lengthmax } = pwd_config

                self.up_pwd_config = {
                    reg: null,
                    passwordrank,
                    lengthmin,
                    lengthmax
                };
                if (passwordrank == 1) {
                    self.up_pwd_config.reg = /^[\w|\d|!@._:,/\-\^\%]+$/;
                } else if (passwordrank == 2) {
                    self.up_pwd_config.reg = /^((?!\D+$)(?![^a-zA-Z]+$))(?![!@._:,/\-\^\%]+$)[\w|\d|!@._:,/\-\^\%]+$/;
                } else if (passwordrank == 3) {
                    self.up_pwd_config.reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@._:,/\-\^\%])[0-9a-zA-Z!@._:,/\-\^\%]+$/;
                }

                var scope = self.$new();
                scope.isRotate = false;
                scope.successModify = false;
                scope.canModifyAfterSuccess = false;
                var adminPasswordModifyModal = $uibModal.open({
                    animation: scope.animationsEnabled,
                    templateUrl: "adminPasswordModify.html",
                    scope: scope
                });
                scope.modify = {
                    prePassword: "",
                    newPassword: "",
                    confirmPassword: ""
                };
                scope.canConfirm = function () {
                    scope.confirmTwice = false;
                };

                scope.$watch(function () {
                    var prevpwd = $("input[name='prePassword']").val();
                    var newpwd = $("input[name='newPassword']").val();
                    return prevpwd + "------" + newpwd;
                }, function (val) {
                    scope.firstStep = false;
                    scope.equalCheck = false;
                    scope.pwdCheck = false;
                    var pres = val.split("------")[0];
                    var news = val.split("------")[1];
                    if (pres == "") {
                        if (news.length) {
                            scope.firstStep = true;
                        }
                    } else {
                        scope.firstStep = false;
                        if (pres == news) {
                            scope.equalCheck = true;
                        } else {
                            scope.equalCheck = false;
                        }
                    }
                    if ((scope.firstStep == false) && (scope.equalCheck == false)) {
                        scope.pwdCheck = true;
                    } else {
                        scope.pwdCheck = false;
                    }
                })
                
                scope.modifyPwd = function (modify, pwdModify) {
                    scope.submitValid = false;
                    if (pwdModify.$valid) {
                        if (scope.pwdCheck == false) {
                            return;
                        }
                        const pwd_length = modify.newPassword.length
                        if (pwd_length < lengthmin || pwd_length > lengthmax) {
                            scope.ispwdlengthshow = true
                            scope.now_timer = setTimeout(() => {
                                scope.ispwdlengthshow = false;
                                clearTimeout(scope.now_timer)
                            }, 1000)
                            return;
                        }

                        scope.isRotate = true;
                        scope.canModifyAfterSuccess = true;
                        var data = {
                            "userName": localStorage.userName,
                            "oldPwd": modify.prePassword,
                            "newPwd": modify.newPassword,
                            "enterpriseUid": localStorage.enterpriseUid,
                            "userId": localStorage.$USERID,
                            "userUid": localStorage.userUid
                        };
                        $http({
                            method: "PUT",
                            url: "awstack-user/v1/enterprises/modify",
                            data: data
                        }).then(function (result) {
                            if (result.code == 0) {
                                scope.canModifyAfterSuccess = true;
                                // tcs对应修改密码
                                $http({
                                    method:"PUT",
                                    url:"awstack-user/v1/enterprises/tcs/modify",
                                    data:data
                                }).then(res =>{
                                    console.log(res);
                                });

                                //修改成功回到登录页面
                                scope.isRotate = false;
                                scope.successModify = true;
                                adminPasswordModifyModal.close();
                                $timeout(function () {
                                    rootScope.logOut();
                                }, 1000);

                            }
                        });
                    } else {
                        scope.submitValid = true;
                    }
                };
                self.$on("modifyerror", function (e, v) {
                    //旧密码错误
                    if (v == "prePasswordError") {
                        scope.isRotate = false;
                        scope.modifyError = 1;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                        scope.confirmTwice = true;
                    }
                    //密码修改错误
                    if (v == "modifyError") {
                        scope.isRotate = false;
                        scope.modifyError = 2;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                        scope.confirmTwice = true;
                    }
                    if (v == "modifyFrequent") {
                        scope.isRotate = false;
                        scope.modifyError = 3;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                    }
                    if (v == "keystoneError") {
                        scope.isRotate = false;
                        scope.modifyError = 4;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                    }
                    if (v == "preventError") {
                        scope.isRotate = false;
                        scope.modifyError = 5;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                    }
                    if (v == "clusterAbnormal") {
                        scope.isRotate = false;
                        scope.modifyError = 6;
                        $timeout(function () {
                            scope.modifyError = "";
                        }, 2500);
                        scope.canModifyAfterSuccess = false;
                    }
                });
            };

        }
    ])
    .directive("topMenuTpl", [function () {
        return {
            restrict: "E",
            templateUrl:"tmpl/top-menu-tpl.html",
            scope:{

            },
            replace:true,
            link: function (scope, element, attrs) {
                
            },
            controller:function($scope,$rootScope,$http,$timeout,$translate){
                let self = $scope,rootScope = $rootScope;
                self.menuData = {
                    curMenu:[]
                };
                var userRole = localStorage.managementRole;
                self.ADMIN = userRole==2?true:false;
                rootScope.domainRole = userRole == '3' ? true : false;
                self.menuMap = [
                    {
                        text: $translate.instant("aws.menu.allMenu"),
                        id: 1,
                        keywords: "allMenu",
                        cloudType: "openStack",
                        child:[]
                    },
                    {
                        text: $translate.instant("aws.menu.privatecloud"),
                        id: 2,
                        keywords: "openStack",
                        cloudType: "openStack",
                        show:true,
                        child:[]
                    },
                    {
                        text: $translate.instant("aws.menu.tencentcloud"),
                        id: 5,
                        keywords: "qcloud",
                        cloudType: "QCLOUD_API_KEY",
                        show:false,
                        child:qcloudMenu
                    },
                    {
                        text: 'VMware',
                        id: 6,
                        keywords: "vMware",
                        cloudType: "VMWARE_API_KEY",
                        show:false,
                        child:vmwareMenu
                    },
                    {
                        text: $translate.instant("aws.menu.aliyun"),
                        id: 4,
                        keywords: "aliyun",
                        cloudType: "ALIYUN_API_KEY",
                        show:false,
                        child:aliyunMenu
                    },
                    {
                        text: 'aws',
                        id: 8,
                        keywords: "aws",
                        cloudType: "AWS_API_KEY",
                        show:false,
                        child:awsMenu
                    },
                    {
                        text: $translate.instant("aws.menu.maas"),
                        id: 7,
                        keywords: "maas",
                        cloudType: "MAAS_API_KEY",
                        child:maasMenu
                    },
                    {
                        text: $translate.instant("aws.menu.Tsafe"),
                        id: 16,
                        keywords: "Tsafe",
                        cloudType: "Tsafe_API_KEY",
                        show:false,
                        child:[]
                    },
                    {
                        text: 'TCS',
                        id: 15,
                        keywords: "TCS",
                        cloudType: "TCS_API_KEY",
                        show:false,
                        child:[]
                    }
                ]
                function getBrowser(){
                    var u_agent = navigator.userAgent;
                    if(u_agent.indexOf('Firefox')>-1){
                        self.browser_name='Firefox';
                    }else if(u_agent.indexOf('Chrome')>-1){
                        //56及以下版本的Chrome采用的处理方法和火狐，IE相同
                        if (parseInt(u_agent.split("Chrome/")[1]) > 56) {
                            self.browser_name='Chrome';
                        } else {
                            self.browser_name='Chrome56';
                        }
                    }else if(u_agent.indexOf('Trident')>-1&&u_agent.indexOf('rv:11')>-1){
                        self.browser_name='IE11';
                    }else if(u_agent.indexOf("Safari")>-1&&u_agent.indexOf('Macintosh')>-1) {
                        self.browser_name='Safari';
                    }
                }
                
                self.menuInitFn = function(list,menuMap,userRole){
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    try{
                        let menuList = JSON.parse(list);
                        let roleAdmin = ['qcloud','vMware','aliyun','aws','maas']
                        menuList = menuList.filter(item=>{
                            let arr = ["SOverview","TCS","Tsafe"];
                            if(arr.indexOf(item.keywords)>-1){
                                menuMap.forEach(it=>{
                                    if(it.keywords==item.keywords){
                                        it.child = item.child;
                                    }
                                })
                            }

                            /* 
                             * 1.针对腾讯云安全模块,没有对接的子模块，
                             * 在数据里面添加埋点，控制菜单的显示与隐藏
                             * 2.在功能管理里面对接后，需要刷新页面 
                            */
                            if(item.keywords=="Tsafe"){
                                //这里只有一个大模块，暂时写死索引，取第一个
                                if(item.child.length>0&&item.child[0].child.length>0){
                                    let tm = item.child[0].child;
                                    let showTitleList = ["yujie","yujian","tianmu"]
                                    tm.map(it=>{
                                        let k = it.active.toLowerCase();
                                        if(!PaasData[k]){
                                            it.hide = true;
                                        }
                                        if(showTitleList.indexOf(it.active)>-1){
                                            it.showTitle = $translate.instant("aws.menuTitle."+it.keywords);
                                        }
                                    })
                                }
                            }

                            /*api未对二级菜单进行href填写，现前端只要有child就自己拼接进href操作，跳转*/
                            if(item.child.length>0){
                                item.child.forEach(it=>{
                                    if(it.child.length>0){
                                        it.href = it.child[0].href;
                                    }
                                })
                                item.href = item.child[0].href;
                            }
                            //过滤掉需要作为独立模块展示的菜单
                            return arr.indexOf(item.keywords)<0;
                        })

                        /* 
                        
                        */
                        //插入私有云菜单
                        menuMap[1].child = menuList;

                        //过滤掉admin用户才能看到的菜单
                        if(userRole!=2){
                            menuMap = menuMap.filter(item=>{
                                return roleAdmin.indexOf(item.keywords)<0;
                            })
                        }

                        //过滤出每个模板里面有子菜单的项
                        menuMap = menuMap.filter(item=>{
                            return item.keywords == "allMenu" || item.child.length>0
                        })
                        console.log(menuMap);
                        return menuMap
                    }catch(err){
                        throw(err,'menulist not invalid')
                    }
                }
                getBrowser();
                self.menuMap = self.menuInitFn(localStorage.menuList,self.menuMap,userRole);
                
                rootScope.vmwareJump = function(item){
                    window.open(`${item.href}&token=${localStorage.$AUTH_TOKEN}`, '_blank');
                }
                /*路由跳转*/
                self.serviceListHide = true;
                rootScope.jumpScreen = function () {
                    window.open('/maxscreen', '_blank');
                };
                rootScope.jumpIntegrated = function () {
                    window.open('/integrated/#/overview?token=' + localStorage.$AUTH_TOKEN, '_blank');
                }
                //统计报表跳转
                rootScope.jumpReportForm = function () {
                    window.open('/reportForm/#/', '_blank');
                }
                //TBase/TStudio的跳转
                rootScope.jumpTStudio = function () {
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    window.open(PaasData['TBase'].TStudio, '_blank');
                }
                //天眼云镜的跳转
                rootScope.jumpSkyCloudSecurity = function (data) {
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    //admin有两个登录入口
                    if (self.ADMIN) {
                        //租户登录入口
                        if (data.keywords == 'Sky_entrance') {
                            window.open(PaasData['SkyCloudSecurity'].tenantUrl + data.href + "?token=" + localStorage.$AUTH_TOKEN);
                        } else if (data.keywords == 'Sky_entrance_manage') {
                            //管理员登录入口
                            window.open(PaasData['SkyCloudSecurity'].url + data.href + "?token=" + localStorage.$AUTH_TOKEN);
                        }
                    } else {
                        //租户登录入口
                        window.open(PaasData['SkyCloudSecurity'].tenantUrl + data.href + "?token=" + localStorage.$AUTH_TOKEN);
                    }
                }

                //灵雀云的跳转
                rootScope.jumpAlauda = function (data) {
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    window.open(PaasData['Alauda'].url + "/dex/auth/simpletoken?client_id=alauda-auth&token=" + localStorage.$AUTH_TOKEN + "&redirect_uri=" + PaasData['Alauda'].url);
                    // Alauda_overview
                }
                //csp存储
                self.hasCookie = false;
                rootScope.jumpCsp = function (data) {
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    let func;
                    $http({
                        method: "GET",
                        url: "awstack-user/v1/back/logincsp"
                    }).then((res) => {
                        if (res) {
                            self.hasCookie = true;
                        }
                    })
                    if (self.hasCookie) {
                        window.open(PaasData['CSP'].url + data.href);
                        return;
                    }
                    func = self.$watch(function () {
                        return self.hasCookie
                    }, function (ne) {
                        if (ne) {
                            window.open(PaasData['CSP'].url + data.href);
                            func();
                        }
                    })
                    
                }

                rootScope.alicloudJump = function (item) {
                    window.open(item.href, '_blank');
                }
                rootScope.maasJump = function (item) {
                    location.href = item.href;
                },
                rootScope.awsJump = function (item) {
                    window.open(item.href, '_blank');
                },
                rootScope.tsafeWaf = function (item) {
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    if(PaasData[item.active]){
                        if(!PaasData[item.active]){return;}
                        window.open(PaasData[item.active].url, '_blank');
                    }else{
                        location.replace("#" + item.href);
                    }
                },
                rootScope.opsJump = function(privates){
                    var PaasData = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                    if (privates.keywords && privates.keywords == 'CMirror_entrance') {
                        window.open(PaasData['CloudSecurity'].url + "?token=" + localStorage.$AUTH_TOKEN);
                        return;
                    }
                    if (privates.keywords && privates.keywords == 'TBDS_entrance') {
                        location.replace("#/TBDS/wellCome");
                        return;
                    }
                    if ((privates.keywords && privates.keywords == 'Sky_entrance') || (privates.keywords && privates.keywords == 'Sky_entrance_manage')) {
                        rootScope.jumpSkyCloudSecurity(privates);
                        return;
                    }
                    if (privates.keywords && privates.keywords == 'Alauda_overview') {
                        rootScope.jumpAlauda(privates);
                        return;
                    }
                    if (privates.keywords && privates.keywords == 'System_SystemSetting_Maxscreen') {
                        rootScope.jumpScreen();
                        return;
                    }
                    if (privates.keywords && privates.keywords == 'Monitoring_Hardware') {
                        rootScope.jumpIntegrated();
                        return;
                    }
                    if (privates.keywords && privates.keywords.indexOf('CSP_') > -1) {
                        rootScope.jumpCsp(privates);
                        return;
                    }
                    if(privates.keywords && privates.keywords=='openStack_view'){
                        location.replace("#/view");
                        return;
                    }
                
                    
                    location.replace("#" + privates.href);
                    
                };
                /*Qcloud跳转*/
                rootScope.qcloudJump = function (item) {
                    if (item) {
                        if (localStorage.$QCLOUD_AUTH_TOKEN) {
                            localStorage.removeItem('$QCLOUD_AUTH_TOKEN')
                        }
                        //localStorage.jumpUrl = item;
                        window.open('/qcloud/#/welcome?jumpRoute=' + item.href, '_blank');
                    }
                }
                self.routeJump = function (item,type) {
                    switch(type){
                        case "QCLOUD_API_KEY":
                            rootScope.qcloudJump(item)
                            break;
                        case "VMWARE_API_KEY":
                            rootScope.vmwareJump(item)
                            break;
                        case "ALIYUN_API_KEY":
                            rootScope.alicloudJump(item);
                            break;
                        case "MAAS_API_KEY":
                            rootScope.maasJump(item);
                            break;
                        case "AWS_API_KEY":
                            rootScope.awsJump(item);
                            break;
                        case "Tsafe_API_KEY":
                            rootScope.tsafeWaf(item);
                            break;
                        default:
                            rootScope.opsJump(item);
                            break;
                    }
                    self.serviceListHide = false;
                    $timeout(function () {
                        self.serviceListHide = true;
                    }, 500)
                    
                }

                /*切换侧边栏主菜单*/
                self.changeMenu = function (item) {
                    self.menuData.menuId = item.keywords;
                    //self.menuData.curMenu = self.allMenuJson[id];
                    if(item.keywords == "allMenu"){
                        self.menuData.curMenu = self.menuMap.filter(item=>item.keywords!='allMenu');
                    }else{
                        self.menuMap.forEach(it=>{
                            if(it.keywords == item.keywords){
                                self.menuData.curMenu = [it];
                            }
                        })
                    }
                    
                }
                
                self.changeMenu(self.menuMap[0]);
               
                self.supportOtherClouds = function (key) {
                    if (localStorage.supportOtherClouds && localStorage.supportOtherClouds.indexOf(key) != -1) {
                        return true;
                    }
                    return false;
                };
                
                self.supportPaas = function (item,type) {
                    if(type=="openStack"){
                        var key = item.keywords;
                        var supportPaas = localStorage.supportPaas ? JSON.parse(localStorage.supportPaas) : {};
                        if (supportPaas[key] && supportPaas[key].isLinked) {
                            return true;
                        }else{
                            return false;
                        }
                    }else{
                        return true;
                    }
                };
            }
        };
    }])
    .directive("a", [function () {
        return {
            restrict: "E",
            link: function (scope, element, attrs) {
                let href = attrs.ngHref;
                if (href && href.toLowerCase().indexOf("http") !== 0) {
                    element.on("click", e => {
                        e.preventDefault();
                        location.hash = href;
                    });
                }
            }
        };
    }])
    .directive("toggleNav", [function () {
        return {
            restrict: "A",
            link: function (scope, element) {
                if (document.body.offsetWidth <= 1365) {
                    element.parent(".main").removeClass("open").addClass("closed");
                } else {
                    element.parent(".main").addClass("open").removeClass("closed");
                }
                element.find(".toggle-icon").on("click", function () {
                    element.parent(".main").toggleClass("open");
                    element.parent(".main").toggleClass("closed");
                    scope.$emit('navToggle');
                    widthFunc()
                });
                function widthFunc() {
                    var width = $(".page-inner").width() - 240;
                    var width1 = $(".page-inner").width();
                    if (width1 < 1035) {
                        width = $(".page-inner").width() - 180;
                        $(".TBDS-header-right.bottom").css("height", 150)
                    } else if (width1 < 1210) {
                        $(".TBDS-header-right.bottom").css("height", 100)
                    } else {
                        $(".TBDS-header-right.bottom").css("height", 50)
                    }
                    $(".TBDS-header-right").css("width", width)
                }

            }
        };
    }])
    .controller("topListCtrl", ["$scope", "$location", "$translate", "$rootScope", "$uibModal", "$http", "$route", "$timeout", "departmentDataSrv", "ticketsSrv", "initSettingSrv",
        function (scope, $location, translate, rootScope, $uibModal, $http, route, $timeout, departmentDataSrv, ticketsSrv, initSettingSrv) {
            var self = scope;
            self.$watch(function () {
                return rootScope.vpcStatus && rootScope.alarmStatus && rootScope.storageStatus && rootScope.transferStatus
            }, function (ne) {
                self.hideRedPoint = ne;
            })

            self.cssDisabled = false;
            if (sessionStorage.getItem("DASHBOARDSTYLE")) {
                $('#setStyle').attr('href', sessionStorage.getItem("DASHBOARDSTYLE"));
            }
            self.changeStyle = function (style) {
                if (self.cssDisabled) return;
                var css = "css/" + style + ".css";
                if (sessionStorage.getItem("DASHBOARDSTYLE")) {
                    if (sessionStorage.getItem("DASHBOARDSTYLE") !== css) {
                        $('#setStyle').attr('href', css);
                        sessionStorage.setItem("DASHBOARDSTYLE", css)
                    }

                } else {
                    $('#setStyle').attr('href', css);
                    sessionStorage.setItem("DASHBOARDSTYLE", css)
                }
                self.cssDisabled = true;
                $timeout(function () {
                    self.cssDisabled = false;
                }, 2000)
            }

            function getUnHandledTickes() {
                if (localStorage.menuList.indexOf("/ticket/pendingTickets") > -1) {
                    rootScope.ListTicket = true;
                } else {
                    rootScope.ListTicket = false;
                }
                ticketsSrv.getMasTask(localStorage.enterpriseUid, localStorage.$USERID).success(function (result) {
                    if (result) {
                        ticketsSrv.unHandledMessage = result;
                        self.unHandledMessage = ticketsSrv.unHandledMessage;
                    } else {
                        self.unHandledMessage = 0;
                    }
                });
                ticketsSrv.getNoSignList(localStorage.enterpriseUid, localStorage.$USERID).success(function (result) {
                    if (result) {
                        ticketsSrv.unHandledTickets = result;
                    }
                });
            }
            self.$watch(function () {
                return rootScope.isLogined;
            }, function (v) {
                if (v) {
                    getUnHandledTickes();
                    self.userName = localStorage.userName;
                    if (localStorage.managementRole != '8' && localStorage.managementRole != '9') {
                        self.show_email = true;
                    } else {
                        self.show_email = false;
                    }
                }
            });
            self.$watch(function () {
                return ticketsSrv.unHandledMessage;
            }, function (data) {
                if (data.data != undefined) {
                    ticketsSrv.unHandledMessage = data.data;
                    self.unHandledMessage = ticketsSrv.unHandledMessage;
                }

            })
            self.$watch(function () {
                return ticketsSrv.unHandledTickets;
            }, function (data) {
                self.unHandledTickes = []
                if (data) {
                    self.totalUnHandledTickes = data.length;
                    if (data.length > 5) {
                        _.map(data, function (item, i) {
                            if (i > 4) {
                                self.ticketsMore = true;
                                return;
                            }
                            self.unHandledTickes.push(item);
                        });
                    } else {
                        self.unHandledTickes = data;
                        self.ticketsMore = false;
                    }
                }
            });
            self.loginJump = function(){
                //审计员 保密员 logo跳转问题  如果是view才跳转首页  tcs相关
                if(localStorage.managementRole == '8'){
                    location.replace("#/permit/secrecyRole");
                }else if(localStorage.managementRole == '9'){
                    location.replace("#/permit/auditRole");
                } else {
                    location.replace("#"+localStorage.loginUrl);
                }
            }
        }
    ])
    .controller("iframeCtrl",["$scope",function($scope){

    }])

const aliyunMenu = [
    {
        keywords: "aliyun_overview",
        text: "概况",
        href: "/alicloud/#/overview"
    },
    {
        keywords: "aliyun_asset",
        text: "云服务器",
        href: "",
        child: [
            {
                keywords: "aliyun_ecs",
                text: "云服务器",
                href: "/alicloud/#/asset/ecs"
            },
            {
                keywords: "aliyun_images",
                text: "镜像",
                href: "/alicloud/#/asset/images"
            },
            {
                keywords: "aliyun_deploymentSet",
                text: "部署集",
                href: "/alicloud/#/asset/deploymentSet"
            }
        ]
    },
    {
        keywords: "aliyun_volumes",
        text: "存储与快照",
        href: "",
        active: "volumes",
        icon: "icon-aw-information",
        child: [
            {
                keywords: "aliyun_disk",
                text: "云盘",
                href: "/alicloud/#/volumes/disk"
            },
            {
                keywords: "aliyun_snapshot",
                text: "快照",
                href: "/alicloud/#/volumes/snapshot"
            }
        ]
    },
    {
        keywords: "aliyun_network",
        text: "网络与安全",
        href: "",
        active: "network",
        icon: "icon-aw-information",
        child: [
            {
                keywords: "aliyun_keyPair",
                text: "SSH密钥",
                href: "/alicloud/#/network/keyPair"
            },
            {
                keywords: "aliyun_securityGroup",
                text: "安全组",
                href: "/alicloud/#/network/securityGroup"
            },
            {
                keywords: "aliyun_eips",
                text: "弹性公网IP",
                href: "/alicloud/#/network/eips"
            },
            {
                keywords: "aliyun_vpc",
                text: "VPC",
                href: "/alicloud/#/network/vpc"
            },
            {
                keywords: "aliyun_routerTable",
                text: "路由表",
                href: "/alicloud/#/network/routerTable"
            }
        ]
    }
]

const qcloudMenu = [
    {
        text: "云服务器",
        keywords: "qcloud_Server",
        child: [
            {
                text: "概况",
                keywords: "qcloud_Survey",
                href: "/"
            },
            {
                text: "云主机",
                keywords: "qcloud_Host",
                href: "/cvm/instances"
            },
            {
                text: "镜像",
                keywords: "qcloud_Image",
                href: "/cvm/image"
            },
            {
                text: "云硬盘",
                keywords: "qcloud_Disk",
                href: "/cvm/cbs"
            },
            {
                text: "快照",
                keywords: "qcloud_Snapshot",
                href: "/cvm/snapshot"
            },
            {
                text: "SSH密钥",
                keywords: "qcloud_SHHkey",
                href: "/cvm/sshkey"
            },
            {
                text: "安全组",
                keywords: "qcloud_Securitygroup",
                href: "/cvm/securitygroup"
            },
            {
                text: "弹性公网IP",
                keywords: "qcloud_Elasticip",
                href: "/cvm/eip"
            },
            {
                text: "专用宿主机",
                keywords: "qcloud_Specialhost",
                href: "/cvm/specialhost"
            },
        ]
    },
    {
        text: "负载均衡",
        keywords: "qcloud_loadBalance",
        child: [
            {
                text: "LB实例列表",
                keywords: "qcloud_LBInstanceList",
                href: "/clb/instance"
            }
        ]
    },
    {
        text: "VPC",
        keywords: "qcloud_VPC",
        child: [
            {
                text: "私有网络",
                keywords: "qcloud_Privatenetwork",
                href: "/vpc/vpc"
            },
            {
                text: "子网",
                keywords: "qcloud_Subnet",
                href: "/vpc/subnet"
            },
            {
                text: "路由表",
                keywords: "qcloud_Routingtable",
                href: "/vpc/route"
            },
            {
                text: "NAT网关",
                keywords: "qcloud_NATgateway",
                href: "/vpc/natgateway"
            }
        ]
    },
    {
        text: "专线接入",
        keywords: "qcloud_dc",
        child: [
            {
                text: "物理专线",
                keywords: "qcloud_dc_dc",
                href: "/dc/dc",
            },
            {
                text: "专用通道",
                keywords: "qcloud_dc_passageway",
                href: "/dc/passageway",
            },
            {
                text: "专线网关",
                keywords: "qcloud_dc_gateway",
                href: "/dc/gateway",
            }
        ]
    }
]

const vmwareMenu = [
    {
        text: "菜单",
        keywords: "vMware_menu",
        child: [
            {
                text: "数据中心",
                keywords: "vMware_Datacenter",
                href: "/vmware/#/?url=datacenter"
            },
            {
                text: "主机",
                keywords: "vMware_Host",
                href: "/vmware/#/?url=host"
            },
            {
                text: "集群",
                keywords: "vMware_colony",
                href: "/vmware/#/?url=cluster"
            },
            {
                text: "虚拟机",
                keywords: "vMware_virtualmachine",
                href: "/vmware/#/?url=virtualmachine"
            }
        ]
    }
]

const maasMenu = [
    {
        text: "物理资源管理",
        keywords: "maas_manage",
        child: [
            {
                text: "概况",
                keywords: "maas_overview",
                href: "/maas/#/overview"
            },
            {
                text: "物理机镜像",
                keywords: "maas_images",
                href: "/maas/#/images"
            },
            {
                text: "网络",
                keywords: "maas_network",
                href: "/maas/#/network"
            },
            {
                text: "注册物理机",
                keywords: "maas_reginstan",
                href: "/maas/#/regInstan"
            },
            {
                text: "申请物理机",
                keywords: "maas_apply",
                href: "/maas/#/applyInstan"
            }
        ]
    }
]

const awsMenu = [
    {
        keywords: "aws_asset",
        text: "云服务器",
        href: "",
        child: [
            {
                keywords: "aws_ecs",
                text: "云服务器",
                href: "/aws/#/asset/ecs"
            },
            {
                keywords: "aws_images",
                text: "镜像",
                href: "/aws/#/asset/images"
            },
            {
                keywords: "aws_keyPair",
                text: "密钥对",
                href: "/aws/#/asset/keyPair"
            },
            {
                keywords: "aws_disk",
                text: "卷",
                href: "/aws/#/asset/disk"
            },
            {
                keywords: "aws_snapshot",
                text: "快照",
                href: "/aws/#/asset/snapshot"
            }
        ]
    },
    {
        keywords: "aws_network",
        text: "VPC",
        href: "",
        active: "network",
        icon: "icon-aw-information",
        child: [
            {
                keywords: "aws_vpc",
                text: "VPC",
                href: "/aws/#/network/vpc"
            },
            {
                keywords: "aws_subnet",
                text: "子网",
                href: "/aws/#/network/subnet"
            },
            {
                keywords: "aws_gateway",
                text: "Internet 网关",
                href: "/aws/#/network/gateway"
            },
            {
                keywords: "aws_router",
                text: "路由表",
                href: "/aws/#/network/router"
            },
            {
                keywords: "aws_securityGroup",
                text: "安全组",
                href: "/aws/#/network/securityGroup"
            },
            {
                keywords: "aws_eips",
                text: "弹性公网IP",
                href: "/aws/#/network/eips"
            },

        ]
    }
]

export {aliyunMenu,qcloudMenu,vmwareMenu,maasMenu,awsMenu}